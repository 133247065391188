<template>
  <div class="syssetting">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>晒图奖励</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div class="ser-m">
      <Form ref="formInline" inline :label-width="70">
        <FormItem label="用户ID" :label-width="53" style="width: 150px;">
          <Input type="text" @on-keyup.13="ser" v-model="serData.user_id" placeholder="用户ID" maxlength="10"></Input>
        </FormItem>
        <FormItem label="用户手机" :label-width="66" style="width: 166px;">
          <Input type="text" @on-keyup.13="ser" v-model="serData.phone" placeholder="用户手机" maxlength="11"></Input>
        </FormItem>
        <FormItem label="用户昵称" :label-width="66" style="width: 166px;">
          <Input type="text" @on-keyup.13="ser" v-model="serData.wx_name" placeholder="用户昵称" maxlength="30"></Input>
        </FormItem>
        <FormItem label="收款方式" :label-width="66" style="width: 140px">
          <Select v-model="serData.collection_type" @on-change="ser">
            <Option :value="0">全部</Option>
            <Option :value="1">支付宝</Option>
            <Option :value="2">微信</Option>
            <Option :value="3">银行卡</Option>
          </Select>
        </FormItem>
        <FormItem label="收款姓名" :label-width="66" style="width: 166px;">
          <Input type="text" @on-keyup.13="ser" v-model="serData.real_name" placeholder="收款姓名" maxlength="30"></Input>
        </FormItem>
        <FormItem label="收款账号" :label-width="66" style="width: 226px;">
          <Input type="text" @on-keyup.13="ser" v-model="serData.account" placeholder="收款账号" maxlength="30"></Input>
        </FormItem>
        <FormItem label="身份证姓名" :label-width="80" style="width: 178px">
          <Input maxlength="30" v-model="serData.name" @on-keyup.13="ser"></Input>
        </FormItem>
        <FormItem label="身份证号码" :label-width="80" style="width: 228px">
          <Input maxlength="18" v-model="serData.rnum" @on-keyup.13="ser"></Input>
        </FormItem>
        <FormItem label="晒图UID" :label-width="58" style="width: 150px">
          <Input maxlength="15" v-model="serData.remark" @on-keyup.13="ser"></Input>
        </FormItem>
        <FormItem label="提现状态" :label-width="66" style="width: 140px;">
          <Select v-model="serData.status" @on-change="ser">
            <Option :value="0">全部</Option>
            <Option :value="2">待审核</Option>
            <Option :value="1">待打款</Option>
            <Option :value="4">已打款</Option>
            <Option :value="3">已驳回</Option>
          </Select>
        </FormItem>
        <FormItem label="申请时间" :label-width="66" style="width: 256px">
          <DatePicker type="daterange" placeholder="申请时间" v-model="serData.create_at" style="width: 190px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem label="处理时间" :label-width="66" style="width: 266px">
          <DatePicker type="daterange" placeholder="处理时间" v-model="serData.exam_at" style="width: 190px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" @click="ser" @keyup.13="ser"><Icon type="ios-search" size="16" />搜索</Button>
          <Divider type="vertical" />
          <Button type="info" ghost @click="downLoadData"><Icon type="ios-download-outline" color="#2db7f5" size="16" />导出</Button>
        </FormItem>
      </Form>
    </div>
    <div>
      <Button size="small" type="success" @click="examSlide(-1,4)">批量确认打款</Button>
      <Divider type="vertical" />
      <Button size="small" type="primary" @click="examSlide(-1,1)">批量审核通过</Button>
      <Divider type="vertical" />
      <Button size="small" type="warning" @click="examSlide(-1,3)">批量拒绝/驳回</Button>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
        <template slot-scope="item" slot="sttit1">
          <div v-viewer>
            <div class="img-list margin-b5">
              <div class="label-t">{{ syscfg.cfg_sttit1 }}：</div>
              <div class="img-c-l">
                <div class="img-c" v-for="(val,index) in JSON.parse(item.row.sttit1)" :key="index">
                  <img v-lazy="val" :alt="syscfg.cfg_sttit1" :title="syscfg.cfg_sttit1" />
                </div>
              </div>
            </div>
           <div class="img-list" >
             <div class="label-t">{{ syscfg.cfg_sttit2 }}：</div>
             <div class="img-c-l">
               <div class="img-c" v-for="(val,index) in JSON.parse(item.row.sttit2)" :key="index">
                 <img v-lazy="val" :alt="syscfg.cfg_sttit2" :title="syscfg.cfg_sttit2" />
               </div>
             </div>
           </div>
          </div>
        </template>
      <template slot-scope="item" slot="user_id">
        <div class="user-msg">
          <div class="head-img"><viewer :options="{'url': 'data-source'}"><img :src="item.row.head_img+'?x-oss-process=style/headimg'" :data-source="item.row.head_img" /></viewer></div>
          <div class="user-l-item">
            <div>昵称：<span>{{item.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{item.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{item.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in item.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[item.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{item.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{item.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{item.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{item.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="item" slot="status">
        <div :style="'color:'+statusMsg[item.row.status].color ">
          {{statusMsg[item.row.status].msg}}
        </div>
        <div v-if="item.row.status == 3">
          驳回原因：{{item.row.reason}}
        </div>
      </template>
      <template slot-scope="item" slot="realinfo">
        <div>身份证姓名：{{item.row.name||''}}</div>
        <div>身份证号：{{item.row.num||''}}</div>
        <div>微信号：{{item.row.wx_num||''}}</div>
      </template>
      <template slot-scope="item" slot="create_at">
        <div>申请时间：{{item.row.create_at}}</div>
        <div v-if="item.row.status != 2">处理时间：{{item.row.exam_at}}</div>
      </template>
      <template slot-scope="item" slot="action">
        <div v-if="item.row.status == 1">
          <Button class="margin-b5" size="small" type="success" @click="examSlide(item.row.id,4)">确认打款</Button>
          <Button size="small" type="error" @click="examSlide(item.row.id,3)">驳回申请</Button>
        </div>
        <div v-else-if="item.row.status == 2">
          <Button class="margin-b5" size="small" type="primary" @click="examSlide(item.row.id,1)">审核通过</Button>
          <Button size="small" type="warning" @click="examSlide(item.row.id,3)">审核拒绝</Button>
        </div>
        <div v-else>--</div>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="pageSize" :page-size-opts="[10,20,30,50,100,200]" @on-change="changePage" @on-page-size-change="changePageSize" />
    </div>
    <Modal v-model="editStatusM" width="360" class-name="exam-status" :title="examStatus == 3?'驳回或拒绝原因':'提示'" :loading="examMLoading" @on-ok="examCom">
      <Input v-if="examStatus == 3" v-model="reason" autofocus type="textarea" :autosize="{minRows: 3,maxRows: 3}" placeholder="请输入驳回或拒绝原因..." />
      <div v-else>{{(examId == -1?'共 ' + checkIdArr.length + ' 条数据确认批量':'确认') + (examStatus == 1?'审核通过':'打款完成了') + '吗？'}}</div>
    </Modal>
  </div>
</template>
<script>
import XLSX from 'xlsx';
export default {
  name: "SlideList",
  data(){
    return {
      columns:[
        {key:'id',minWidth:40,align: 'center','fixed':'left',
          renderHeader: (h ) => {
            return h('div',[
              h('Checkbox', {
                props: {
                  value:this.isAll, trueValue:1, falseValue:0
                },
                on: {
                  'on-change': (event) => {
                    this.isAll = event;
                    this.checkIdArr = [];
                    this.data.forEach((item,key)=>{
                      if(this.data[key].status == 2 || this.data[key].status == 1){
                        this.data[key].checkSataus = event?1:0;
                        if(event) this.checkIdArr.push(this.data[key].id);
                      }
                    })
                  }
                }
              })
            ])
          },
          render: (h , params) => {
            return h('div',[
              h('Checkbox', {
                props: {
                  value:params.row.status >= 3?0:params.row.checkSataus, trueValue:1,falseValue:0, disabled:params.row.status >= 3?true:false
                },
                on: {
                  'on-change': (event) => {
                    this.data[params.index].checkSataus = event;
                    let isALL = 1;
                    this.checkIdArr = [];
                    this.data.forEach((item,key)=>{
                      if(this.data[key].status == 2 || this.data[key].status == 1){
                        if(this.data[key].checkSataus != 1) isALL = 0;
                        else this.checkIdArr.push(this.data[key].id);
                      }
                    })
                    this.isAll = isALL;
                  }
                }
              })
            ])
          }
        },
        {title: '用户信息',slot: 'user_id',minWidth:210,'fixed':'left'},
        {title: '业绩金额',key: 'performance_amount',minWidth:100,align: 'center','fixed':'left'},
        {title: '应打款金额',key: 'payment_amount',minWidth:80,align: 'center','fixed':'left',
          render: (h, params) => {
            return  h('span', {style:{color:'#cc3300'}}, '￥' + params.row.payment_amount);
          }
        },
        {title: '晒图信息',slot: 'sttit1',minWidth:140},
        {title: '提现类型',key: 'collection_type',minWidth:70,align: 'center',
          render: (h, params) => {
            return  h('span', params.row.collection_type==1?'支付宝':(params.row.collection_type==2?'微信':'银行卡'));
          }
        },
        {title: '提现姓名',key: 'real_name',minWidth:70},
        {title: '提现账号',key: 'account',minWidth:150},
        {title: '实名信息',slot: 'realinfo',minWidth:220},
        {title: '晒图UID',key: 'remark',minWidth:100,
          render: (h, params) => {
            return  h('span', params.row.remark == ''?'--':params.row.remark);
          }
        },
        {title: '日期',slot: 'create_at',minWidth:200,align: 'center'},
        {title: '状态',slot: 'status',minWidth:70,'fixed':'right'},
        {title: '操作',slot:'action',width:100,align:'center','fixed':'right'},
      ],
      data:[],
      serData:{user_id:'',phone:'',wx_name:'',collection_type:0,real_name:'',account:'',name:'',rnum:'',remark:'',status:0,create_at:'',exam_at:''},
      page:1,
      pageSize:0,
      total:0,
      loading:false,
      statusMsg:[],
      cfg:{platform: {},level:{}},
      syscfg:{},
      isAll:0,
      checkIdArr:[],
      reason:'',
      examMLoading:true,
      examStatus:0,
      examId:0,
      editStatusM:false
    }
  },
  created() {
    let query={};
    if(typeof this.$route.query.status!="undefined"){
      this.serData.status=parseInt(this.$route.query.status);
      query.status=this.$route.query.status||0;
    }
    this.$router.push({query});
    this.$store.commit('add_tagsview',this.$route);
    this.init();
  },
  watch:{
    '$route.query':function (query){
      if(query.newpage==1){
        query={'status':query.status||0};
        query.status=parseInt(query.status);
        Object.assign(this.serData,query);
        this.$router.push({query});
        this.$store.commit('add_tagsview',this.$route);
        this.init();
      }
    }
  },
  methods:{
    init:function(){
      let _this = this;
      this.loading = true;
      this.isAll = 0;
      this.reason = '';
      this.requestApi('/adm/slideShowList',{page_size:this.pageSize,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.statusMsg = res.data.statusMsg;
          _this.cfg = res.data.cfg;
          _this.syscfg = res.data.syscfg;
          _this.total = res.data.page.total;
          _this.current_page = res.data.page.current;
          _this.pageSize = res.data.page.limit;
          _this.data = res.data.list;
        }
      })
    },
    ser:function(){
      this.page = 1;
      this.init();
    },
    downLoadData:function(){
      let _this = this,
        title={
          id:'会员ID',
          wx_name:'昵称',
          user_phone:'手机号',
          wx_num:'微信号',
          performance_amount:'业绩金额',
          payment_amount:'打款金额',
          collection_type:'账户类型',
          bank_name:'银行名称',
          account:'收款账号',
          bank_mobile:'预留手机号',
          real_name:'真实姓名',
          status:'审核状态',
          create_at:'提交日期',
          remark:'备注',
          exam_at:'审核日期',
          reason:'驳回原因'
        };
      this.export_loading=true;
      this.requestExcel('/adm/exportSlideShowList',title,{page:1,ser_data:this.serData}).then(function (res){
        _this.export_loading=false;
        if(res.status!=1) return _this.alertError(res.msg);
        const sheet=XLSX.utils.aoa_to_sheet(res.data);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet, 'Sheet1');
        XLSX.writeFile(book, '用户列表.xlsx');
      });
    },
    examSlide:function(id,examStatus){
      this.examId = id;
      this.examStatus = examStatus;
      if(id == -1 && this.checkIdArr.length <= 0){
        return this.$Modal.error({
          title: '提示',
          content: '请选择待处理数据！'
        });
      }else this.editStatusM = true;
    },
    examCom:function(){
      if(this.examStatus == 3 && this.reason == ''){
        this.examMLoading = false;
        setTimeout(()=>{
          this.examMLoading = true;
        },10)
        return this.$Message.error('请输入驳回或拒绝原因');
      }
      let _this = this;
      this.requestApi('/adm/slideShowExam',{id_arr:this.checkIdArr,exam_status:this.examStatus,reason:this.reason,exam_id:this.examId}).then(function (res){
        if(res.status==1){
          _this.init();
          _this.$Message.success(res.msg);
          _this.editStatusM =false;
        }else{
          _this.$Message.error(res.msg);
          _this.examMLoading = false;
          setTimeout(()=>{
            _this.examMLoading = true;
          },10)
        }
      })
    },
    changePage:function (page){
      this.page = page;
      this.init();
    },
    changePageSize:function (pageSize){
      this.pageSize = pageSize;
      this.page = 1
      this.init();
    }
  }
}
</script>

<style lang="less">
  .img-list{
    .label-t{
      color: #666666;
    }
    .img-c-l{
      margin-top:5px;
      .img-c{
        display: inline-block; height: 50px; line-height: 47px; margin-right: 15px;
        img{
          max-width:50px;max-height: 100%;vertical-align: middle; cursor: zoom-in;
        }
      }
    }
  }
  .user-msg{
    display: table; width: auto;height: 100px;
    .head-img{
      display: table-cell; width: 40px;height: 40px;border-radius: 20px;vertical-align: middle;
      img{
        width: 40px;height: 40px;border-radius: 20px; cursor: zoom-in;
      }
    }
    .user-l-item{
      display: table-cell; width: auto;vertical-align: middle;padding-left: 5px;
      span{
        color: #2d8cf0;
      }
    }
  }
  .margin-b5{
    margin-bottom:5px;
  }
  .exam-status{
    .ivu-modal-header,.ivu-modal-footer{
      border:none;
    }
    .ivu-modal-close{
      display: none;
    }
  }
</style>